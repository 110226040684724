import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import SendIcon from "@/core/ui/iconsax/linear/send-2.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CurriculumProfileListModalListItem from "@/product/course/curriculum/common/modal/item/CurriculumProfileListModalListItem"
import { CurriculumProfileListModalTab } from "@/product/course/curriculum/common/modal/tab/CurriculumProfileListModalTabList"
import { CurriculumProfileListModalContentCSVMutation } from "@/product/course/curriculum/common/modal/__generated__/CurriculumProfileListModalContentCSVMutation.graphql"
import { CurriculumProfileListModalContentQuery } from "@/product/course/curriculum/common/modal/__generated__/CurriculumProfileListModalContentQuery.graphql"
import useEmailProductMembers from "@/product/course/members/hooks/useEmailProductMembers"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import {
  DiscoButton,
  DiscoCheckbox,
  DiscoText,
  DiscoTooltip,
  useModalContext,
} from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import { useState } from "react"
import ReactDOM from "react-dom"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export type ProductMembershipUserShape = {
  id: string
  member: UserAvatarShape
}

interface CurriculumProfileListModalContentProps {
  activeTab: CurriculumProfileListModalTab
  contentUsageId: GlobalID
}

function CurriculumProfileListModalContent({
  activeTab,
  contentUsageId,
}: CurriculumProfileListModalContentProps) {
  const classes = useStyles()
  const activeProduct = useActiveProduct()!
  const memberLabel = useLabel("admin_member")

  const { product, contentUsage } =
    useLazyLoadQuery<CurriculumProfileListModalContentQuery>(
      graphql`
        query CurriculumProfileListModalContentQuery($id: ID!, $contentUsageId: ID!) {
          product: node(id: $id) {
            ... on Product {
              id
              attendees {
                edges {
                  node {
                    id
                    member {
                      id
                      first_name: firstName
                      last_name: lastName
                      avatar
                    }
                  }
                }
              }
            }
          }
          contentUsage: node(id: $contentUsageId) {
            ... on ContentUsage {
              completedProductMemberships {
                edges {
                  node {
                    id
                    member {
                      id
                      first_name: firstName
                      last_name: lastName
                      avatar
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        id: activeProduct.id,
        contentUsageId,
      }
    )

  const modal = useModalContext()

  const completedProductMembers = Relay.connectionToArray(
    contentUsage?.completedProductMemberships
  )

  const attendees = Relay.connectionToArray(product!.attendees)

  const incompleteProductMembers: ProductMembershipUserShape[] = []

  attendees.forEach((attendee) => {
    if (
      completedProductMembers.every(
        (completedProductMember) => completedProductMember.id !== attendee.id
      )
    ) {
      incompleteProductMembers.push(attendee)
    }
  })

  const [selectedCompletedProductMembers, setSelectedCompletedProductMembers] = useState<
    ProductMembershipUserShape[]
  >([])
  const isSelectAllCompletedCheckboxActive =
    completedProductMembers.length !== 0 &&
    completedProductMembers.length === selectedCompletedProductMembers.length

  const [selectedIncompleteProductMembers, setSelectedIncompleteProductMembers] =
    useState<ProductMembershipUserShape[]>([])
  const isSelectAllIncompleteCheckboxActive =
    incompleteProductMembers.length !== 0 &&
    incompleteProductMembers.length === selectedIncompleteProductMembers.length

  const emailProductMembers = useEmailProductMembers()
  const [isCreatingCsv, setIsCreatingCsv] = useState(false)
  const isMembersSelected =
    Boolean(selectedCompletedProductMembers.length) ||
    Boolean(selectedIncompleteProductMembers.length)
  const createContentCompletionCsv =
    Relay.useAsyncMutation<CurriculumProfileListModalContentCSVMutation>(
      graphql`
        mutation CurriculumProfileListModalContentCSVMutation(
          $input: CreateContentCompletionCsvInput!
        ) {
          response: createContentCompletionCsv(input: $input) {
            downloadCSVLink
            errors {
              field
              message
            }
          }
        }
      `
    )

  const buttons = (
    <>
      <DiscoTooltip
        content={`Cannot export to CSV when there are ${memberLabel.plural} selected. Please unselect the ${memberLabel.plural} to export to CSV.`}
        disabled={!isMembersSelected}
      >
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          onClick={handleDownloadCSV}
          disabled={isMembersSelected || isCreatingCsv}
          shouldDisplaySpinner={isCreatingCsv}
        >
          {"Export CSV"}
        </DiscoButton>
      </DiscoTooltip>
      <DiscoButton
        leftIcon={<SendIcon width={18} height={18} />}
        onClick={handleEmailSelected}
        disabled={Boolean(
          !selectedCompletedProductMembers.length &&
            !selectedIncompleteProductMembers.length
        )}
      >
        {"Send Email"}
      </DiscoButton>
    </>
  )

  return (
    <>
      {activeTab === "completed" ? (
        // Completed Users
        <div className={classes.listContainer}>
          <DiscoCheckbox
            className={classes.selectAll}
            testid={"CurriculumProfileListModal.completed.select-all"}
            label={<DiscoText color={"text.secondary"}>{"Select All"}</DiscoText>}
            onChange={handleSelectAllCompleted}
            checked={isSelectAllCompletedCheckboxActive}
            labelPlacement={"end"}
            disabled={completedProductMembers.length === 0}
          />
          {completedProductMembers.map((completedProductMember, index) => (
            <CurriculumProfileListModalListItem
              key={completedProductMember.id!}
              user={completedProductMember}
              testid={`CurriculumProfileListModalListItem-complete-${index}`}
              isSelected={selectedCompletedProductMembers.includes(
                completedProductMember
              )}
              setSelectedProductMembers={setSelectedCompletedProductMembers}
            />
          ))}
        </div>
      ) : (
        // Incomplete Users
        <div className={classes.listContainer}>
          <DiscoCheckbox
            className={classes.selectAll}
            testid={"CurriculumProfileListModal.incomplete.select-all"}
            label={<DiscoText color={"text.secondary"}>{"Select All"}</DiscoText>}
            onChange={handleSelectAllIncomplete}
            checked={isSelectAllIncompleteCheckboxActive}
            labelPlacement={"end"}
            disabled={incompleteProductMembers.length === 0}
          />
          {incompleteProductMembers.map((incompleteProductMember, index) => (
            <CurriculumProfileListModalListItem
              key={incompleteProductMember.id!}
              user={incompleteProductMember}
              testid={`CurriculumProfileListModalListItem-incomplete-${index}`}
              isSelected={selectedIncompleteProductMembers.includes(
                incompleteProductMember
              )}
              setSelectedProductMembers={setSelectedIncompleteProductMembers}
            />
          ))}
        </div>
      )}
      {modal?.buttonsRef ? ReactDOM.createPortal(buttons, modal.buttonsRef) : buttons}
    </>
  )

  function handleSelectAllCompleted() {
    if (isSelectAllCompletedCheckboxActive) {
      setSelectedCompletedProductMembers([])
    } else {
      setSelectedCompletedProductMembers(completedProductMembers)
    }
  }

  function handleSelectAllIncomplete() {
    if (isSelectAllIncompleteCheckboxActive) {
      setSelectedIncompleteProductMembers([])
    } else {
      setSelectedIncompleteProductMembers(incompleteProductMembers)
    }
  }

  async function handleDownloadCSV() {
    setIsCreatingCsv(true)
    try {
      const { response } = await createContentCompletionCsv({
        input: { contentUsageId },
      })
      if (response.downloadCSVLink) {
        window.location.href = response.downloadCSVLink
      }
    } finally {
      setIsCreatingCsv(false)
    }
  }

  async function handleEmailSelected() {
    const selectedProductMembers: ProductMembershipUserShape[] = [
      ...selectedCompletedProductMembers,
      ...selectedIncompleteProductMembers,
    ]
    await emailProductMembers({
      productMembershipIds: selectedProductMembers.map((user) => user.id!),
    })
  }
}

function CurriculumProfileListModalContentSkeleton() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.listContainer}>
      <Skeleton
        height={"56px"}
        style={{ borderRadius: theme.measure.borderRadius.medium }}
      />
      <Skeleton
        height={"56px"}
        style={{ borderRadius: theme.measure.borderRadius.medium }}
      />
      <Skeleton
        height={"56px"}
        style={{ borderRadius: theme.measure.borderRadius.medium }}
      />
    </div>
  )
}
const useStyles = makeUseStyles((theme) => ({
  listContainer: {
    margin: theme.spacing(1.25, 0),
  },
  selectAll: {
    marginLeft: 0,
  },
}))

export default Relay.withSkeleton({
  component: CurriculumProfileListModalContent,
  skeleton: CurriculumProfileListModalContentSkeleton,
})
