import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { withDiscoDefaultTheme } from "@/core/context/CustomThemeProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { useStopViewAsMember } from "@/core/route/util/routeUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Toolbar } from "@material-ui/core"

function ViewAsAppBar() {
  const classes = useStyles()
  const isViewAsMember = useIsAdminViewingAsMember()
  const activeProduct = useActiveProduct()
  const memberLabel = useLabel(activeProduct ? "product_member" : "organization_member")
  const stopViewAsMember = useStopViewAsMember()
  const isWebView = useIsWebView()

  if (!isViewAsMember || isWebView) return null

  return (
    <Toolbar
      classes={{
        root: classes.toolbarRoot,
      }}
    >
      <DiscoText
        testid={"ViewAsAppBar.viewing-as-member"}
        color={"common.white"}
        variant={"body-sm-600"}
      >
        {`Previewing as ${memberLabel.singular}`}
      </DiscoText>
      <DiscoButton
        testid={"ViewAsAppBar.turn-off-button"}
        color={"grey"}
        onClick={stopViewAsMember}
        size={"small"}
      >
        {"Exit Mode"}
      </DiscoButton>
    </Toolbar>
  )
}

export default withDiscoDefaultTheme(ViewAsAppBar)

const useStyles = makeUseStyles((theme) => ({
  toolbarRoot: {
    display: "flex",
    width: "100%",
    minHeight: "56px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.groovy.neutral[600],
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}))
