import CurriculumConfirmationModal from "@/content-usage/ modals/CurriculumConfirmationModal"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import {
  InlineContentDrawerFormState,
  InlineContentDrawerMode,
} from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { ContentFormState } from "@/content/form/util/contentFormUtil"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import SubmitAssignmentFormFields from "@/product/course/assignments/submission/SubmitAssignmentFormFields"
import { SubmitAssignmentFormFragment$key } from "@/product/course/assignments/submission/__generated__/SubmitAssignmentFormFragment.graphql"
import {
  SubmitAssignmentFormMutation,
  SubmitAssignmentInput,
} from "@/product/course/assignments/submission/__generated__/SubmitAssignmentFormMutation.graphql"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Countdown from "@components/countdown/Countdown"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { ConnectionHandler, graphql } from "relay-runtime"

interface Props {
  mode: InlineContentDrawerMode
  contentForm: FormStore<InlineContentDrawerFormState>
  contentUsageKey: SubmitAssignmentFormFragment$key | null
  contentLabel: string
}

function SubmitAssignmentForm(props: Props) {
  const { mode, contentForm, contentUsageKey, contentLabel } = props

  const { authUser } = useAuthUser({ required: true })
  const timezone = useUserTimezone()
  const labels = useLabels()
  const confirmationModalDisclosure = useDisclosure()
  const classes = useStyles()
  const theme = useTheme()

  const contentUsage = useFragment<SubmitAssignmentFormFragment$key>(
    graphql`
      fragment SubmitAssignmentFormFragment on ContentUsage {
        id
        dueAt
        submissionVisibility
        ...SubmitAssignmentFormFieldsFragment
        ...ContentUsageUtils_useSubmissionDeadlineFragment
        confirmationModalContent {
          ...CurriculumConfirmationModalConfirmationModalFragment
        }
        ...usePermissionsFragment
      }
    `,
    contentUsageKey
  )

  const canCompleteCurriculum = usePermissions(contentUsage).has("curriculum.complete")

  const form = useFormStore<
    SubmitAssignmentFormMutation,
    SubmitAssignmentInput & ContentFormState
  >(
    graphql`
      mutation SubmitAssignmentFormMutation(
        $input: SubmitAssignmentInput!
        $connections: [ID!]!
      ) {
        response: submitAssignment(input: $input) {
          node
            @prependNode(
              connections: $connections
              edgeTypeName: "AssignmentSubmissionNodeConnection"
            ) {
            id
            contentUsage {
              id
              assignmentSubmissions {
                totalCount
              }
              viewerSubmission {
                id
                createdAt
                submissionUsers {
                  edges {
                    node {
                      id
                      productMembershipId
                      user {
                        id
                      }
                    }
                  }
                }
              }
              ...ContentUsageUtils_ContentCompletionFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentUsageId: contentUsage?.id,
      users: authUser ? [authUser.id] : [],
      content: {},
    }
  )

  const { dueAt, isLateSubmission } = ContentUsageUtils.useSubmissionDeadline({
    mode,
    contentForm,
    contentUsageKey: contentUsage,
  })

  return (
    <>
      <Form
        id={"submit-assignment"}
        onSubmit={
          contentUsage?.confirmationModalContent
            ? confirmationModalDisclosure.onOpen
            : handleSubmit
        }
        buttons={
          <DiscoTooltip
            content={getDisabledSubmitButtonTooltipContent()}
            disabled={canCompleteCurriculum && mode === "read"}
          >
            <div>
              <Form.SubmitButton
                id={"submit-assignment"}
                form={form}
                testid={"SubmitAssignmentForm.submit-button"}
                // Always disable submit in edit modes
                disabled={!canCompleteCurriculum || mode !== "read"}
                // Late submission is a white button with icon
                variant={isLateSubmission ? "outlined" : undefined}
                color={isLateSubmission ? "grey" : undefined}
                leftIcon={
                  isLateSubmission ? (
                    <DiscoTooltip
                      content={
                        "You can still submit your work but your submission will be marked as 'Late Submission'"
                      }
                    >
                      <div>
                        <DiscoIcon icon={"time"} color={theme.palette.text.primary} />
                      </div>
                    </DiscoTooltip>
                  ) : undefined
                }
              >
                {`Submit ${contentLabel}`}
              </Form.SubmitButton>
            </div>
          </DiscoTooltip>
        }
      >
        <div className={classes.header}>
          <div className={classes.headerText}>
            <DiscoText
              variant={"body-md-600"}
              marginBottom={0.25}
              color={"groovy.neutral.700"}
            >
              {`Submit ${contentLabel}`}
            </DiscoText>
            <DiscoText variant={"body-sm"} color={"groovy.neutral.500"}>
              {visibilityMessage()}
            </DiscoText>
            {dueAt && (
              <div className={classes.dueDate}>
                <DiscoIcon
                  icon={"calendar"}
                  color={
                    isLateSubmission
                      ? theme.palette.error.main
                      : theme.palette.text.secondary
                  }
                />
                <DiscoText
                  variant={"body-sm"}
                  color={isLateSubmission ? "error.main" : "text.secondary"}
                >
                  {"Due date: "}
                  <span className={classes.dueDateText}>
                    {ContentUsageUtils.getFormattedDueDate(dueAt, timezone, "@")}
                  </span>
                </DiscoText>
              </div>
            )}
          </div>
          {dueAt && (
            <Countdown
              // key resets the counter if admin changes date in form so that
              // if disabled prop changes with a new date the countdown still rerenders
              key={dueAt.toISOString()}
              testid={"SubmitAssignmentForm.countdown"}
              dateInUtc={dueAt}
              timerDelayInSeconds={1}
              disabled={isLateSubmission}
              className={classes.countdown}
            />
          )}
        </div>

        <SubmitAssignmentFormFields
          form={form}
          contentForm={contentForm}
          contentDrawerMode={mode}
          contentUsageKey={contentUsage}
        />
      </Form>
      {contentUsage?.confirmationModalContent && (
        <CurriculumConfirmationModal
          content={contentUsage.confirmationModalContent}
          isOpen={confirmationModalDisclosure.isOpen}
          onClose={confirmationModalDisclosure.onClose}
          onConfirm={handleSubmit}
          isLoading={form.isSubmitting}
        />
      )}
    </>
  )

  function getDisabledSubmitButtonTooltipContent() {
    if (!canCompleteCurriculum) {
      return "This action cannot be taken while previewing."
    } else if (mode !== "read") {
      return `You cannot submit while editing this ${contentLabel}`
    } else if (!canCompleteCurriculum) {
      return "This action cannot be done while in previewing."
    }
    return ""
  }

  async function handleSubmit() {
    const connections = []

    if (contentUsage) {
      connections.push(
        ConnectionHandler.getConnectionID(
          contentUsage.id,
          "ContentUsageSubmissionTablePage__assignmentSubmissions"
        )
      )
    }

    const { didSave } = await form.submit(
      {
        contentUsageId: form.state.contentUsageId,
        users: form.state.users,
        content: form.state.content,
      },
      {
        connections,
        updater: (store) => {
          if (!contentUsage) return
          const cuRecord = store.get(contentUsage.id)
          if (!cuRecord) return

          ConnectionHandlerPlus.getConnections(
            cuRecord,
            "ContentUsageSubmissionTable__assignmentSubmissions"
          ).forEach((connection) => connection.invalidateRecord())
        },
      }
    )

    if (!didSave) return

    displaySuccessToast({
      message: "Submission received!",
      testid: "SubmitAssignmentForm.success-toast",
    })
  }

  function visibilityMessage() {
    const visibility =
      mode === "read"
        ? contentUsage!.submissionVisibility
        : contentForm.state.contentUsageInput?.submissionVisibility
    switch (visibility) {
      case "private":
        return `Only ${labels.product_admin.plural} will see your submission`
      case "cohort":
        return `All ${labels.product_member.plural} will see your submission`
      case "member_group":
        return `Only members of your sub-groups will see your submission`
    }
    return ""
  }
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  headerText: {
    flex: "1 1 auto",
  },
  countdown: {
    flex: "0 0 auto",
    width: "auto",
    margin: "auto",
    justifySelf: "center",
  },
  dueDate: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gap: theme.spacing(0.5),
    alignItems: "center",
    marginTop: theme.spacing(1.5),
  },
  dueDateText: {
    ...theme.typography["body-sm"],
    ...theme.typography.modifiers.fontWeight[600],
  },
}))

export default observer(SubmitAssignmentForm)
