import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import {
  MailToProductMembersFilterInput,
  useEmailProductMembersMutation,
} from "@/product/course/members/hooks/__generated__/useEmailProductMembersMutation.graphql"
import Relay from "@/relay/relayUtils"
import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"
import { useCallback } from "react"
import { graphql } from "relay-runtime"

function useEmailProductMembers() {
  const { authUser } = useAuthUser({ required: true })
  const activeProduct = useActiveProduct()!

  const commitMutation = Relay.useAsyncMutation<useEmailProductMembersMutation>(
    graphql`
      mutation useEmailProductMembersMutation($input: MailToProductMembersInput!) {
        response: mailToProductMembers(input: $input) {
          mailTo
          errors {
            field
            message
          }
        }
      }
    `
  )

  return useCallback(
    async (
      {
        productMembershipIds,
        mailAll,
      }: {
        productMembershipIds?: string[]
        mailAll?: boolean
      },
      filters?: MailToProductMembersFilterInput
    ) => {
      const { response } = await commitMutation({
        input: {
          productId: activeProduct.id,
          fromEmail: authUser.email!,
          productMembershipIds,
          mailAll,
          filters,
        },
      })

      if (response.errors) {
        displayGraphQLErrorToast(response.errors[0])
      }

      if (response.mailTo) {
        window.open(response.mailTo)
      }
    },
    [authUser.email, commitMutation, activeProduct.id]
  )
}

export default useEmailProductMembers
