/**
 * @generated SignedSource<<be64d96d810a327de07a4d58c13cd222>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CurriculumProfileListModalContentQuery$variables = {
  id: string;
  contentUsageId: string;
};
export type CurriculumProfileListModalContentQuery$data = {
  readonly product: {
    readonly id?: string;
    readonly attendees?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly member: {
            readonly id: string;
            readonly first_name: string | null;
            readonly last_name: string | null;
            readonly avatar: string;
          };
        };
      }>;
    };
  } | null;
  readonly contentUsage: {
    readonly completedProductMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly member: {
            readonly id: string;
            readonly first_name: string | null;
            readonly last_name: string | null;
            readonly avatar: string;
          };
        };
      }>;
    };
  } | null;
};
export type CurriculumProfileListModalContentQuery = {
  variables: CurriculumProfileListModalContentQuery$variables;
  response: CurriculumProfileListModalContentQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductMembershipNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductMembership",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "member",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "first_name",
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": "last_name",
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMembershipNodeConnection",
  "kind": "LinkedField",
  "name": "attendees",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "contentUsageId"
  }
],
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "completedProductMemberships",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurriculumProfileListModalContentQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "contentUsage",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CurriculumProfileListModalContentQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "contentUsage",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v7/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "382dab3038163bf18d6553451e88f0dd",
    "id": null,
    "metadata": {},
    "name": "CurriculumProfileListModalContentQuery",
    "operationKind": "query",
    "text": "query CurriculumProfileListModalContentQuery(\n  $id: ID!\n  $contentUsageId: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    ... on Product {\n      id\n      attendees {\n        edges {\n          node {\n            id\n            member {\n              id\n              first_name: firstName\n              last_name: lastName\n              avatar\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n  contentUsage: node(id: $contentUsageId) {\n    __typename\n    ... on ContentUsage {\n      completedProductMemberships {\n        edges {\n          node {\n            id\n            member {\n              id\n              first_name: firstName\n              last_name: lastName\n              avatar\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c04af3360f55d2572093f73afa062bba";

export default node;
