import { useLabel } from "@/core/context/LabelsContext"
import { ProductDeleteConfirmationModalPreloadedQuery } from "@/product/delete/__generated__/ProductDeleteConfirmationModalPreloadedQuery.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoText } from "@disco-ui"
import DiscoUnorderedList from "@disco-ui/list/unordered-list/DiscoUnorderedList"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay"

type Props = {
  onClose: VoidFunction
  isOpen: boolean
  confirmButtonProps: {
    onClick: VoidFunction
    disabled: boolean
    shouldDisplaySpinner: boolean
  }
  productQueryRef: PreloadedQuery<ProductDeleteConfirmationModalPreloadedQuery>
} & TestIDProps

export const ProductDeleteConfirmationModalPreloadedRequest = graphql`
  query ProductDeleteConfirmationModalPreloadedQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Product {
        id
        name
        type
        parentPathwayProducts {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

function ProductDeleteConfirmationModal({
  testid = "ProductDeleteConfirmationModal",
  onClose,
  isOpen,
  confirmButtonProps,
  productQueryRef,
}: Props) {
  const experienceLabel = useLabel("admin_experience")

  const { node } = usePreloadedQuery<ProductDeleteConfirmationModalPreloadedQuery>(
    ProductDeleteConfirmationModalPreloadedRequest,
    productQueryRef
  )

  const product = Relay.narrowNodeType(node, "Product")
  if (!product) return null

  const parentPathwayProducts = Relay.connectionToArray(product.parentPathwayProducts)
  const hasParentPathways = !!parentPathwayProducts.length
  const pluralizedPathway = pluralize("Pathway", parentPathwayProducts.length)

  return (
    <DiscoConfirmationModal
      testid={`${testid}.delete-warning-modal`}
      isOpen={isOpen}
      onClose={onClose}
      title={`Are you sure you want to delete this ${experienceLabel.singular}?`}
      description={getDescription()}
      modalContentLabel={"Archive Product Dialog"}
      typeToConfirmText={product.name}
      disableTypeToConfirm={hasParentPathways}
      confirmButtonProps={{
        ...confirmButtonProps,
        disabled: hasParentPathways || confirmButtonProps.disabled,
        children: `I understand, delete this ${experienceLabel.singular}`,
      }}
    />
  )

  function getDescription() {
    if (hasParentPathways)
      return (
        <>
          <DiscoText>{`You must remove this ${experienceLabel.singular} from the following ${pluralizedPathway} before deleting:`}</DiscoText>
          <DiscoUnorderedList listItems={parentPathwayProducts.map(({ name }) => name)} />
        </>
      )
    return `This action is permanent and will result in the removal of the ${experienceLabel.singular}. All configuration of this ${experienceLabel.singular} will be lost.`
  }
}

export default Relay.withSkeleton({
  component: ProductDeleteConfirmationModal,
  skeleton: () => null,
})
