import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ProductMembershipUserShape } from "@/product/course/curriculum/common/modal/CurriculumProfileListModal"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import { getUserFullName } from "@/user/util/userUtils"
import { DiscoCheckbox, DiscoText } from "@disco-ui"
import React from "react"

interface CurriculumProfileListModalListItemProps {
  user: ProductMembershipUserShape
  testid?: string
  isSelected?: boolean
  setSelectedProductMembers: React.Dispatch<
    React.SetStateAction<ProductMembershipUserShape[]>
  >
}

function CurriculumProfileListModalListItem({
  user,
  testid,
  isSelected = false,
  setSelectedProductMembers,
}: CurriculumProfileListModalListItemProps) {
  const classes = useStyles()

  return (
    <div className={classes.itemContainer} data-testid={testid}>
      <div className={classes.checkboxContainer}>
        <DiscoCheckbox
          testid={"CurriculumProfileListModalListItem.select-button"}
          label={null}
          onChange={handleSelectUser}
          checked={isSelected}
        />
      </div>

      <div className={classes.avatarContainer}>
        {/* Avatar */}
        <UserAvatar
          className={classes.userAvatar}
          placeholderClassName={classes.avatarPlaceholder}
          placeholderTextClassName={classes.avatarPlaceholderText}
          testid={`${testid}.MultipleAvatars.${user.member.first_name}`}
          user={user.member}
          size={40}
        />
      </div>

      <div className={classes.userContainer}>
        {/* Name */}
        <DiscoText className={classes.nameContainer} testid={`${testid}.name`}>
          {user?.member.name ?? getUserFullName(user.member)}
        </DiscoText>
      </div>
    </div>
  )

  function handleSelectUser() {
    setSelectedProductMembers((selectedUsers) => {
      if (isSelected) {
        return selectedUsers.filter((u) => u.id !== user.id)
      }
      return [...selectedUsers, user]
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1.5),

    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
  checkboxContainer: {
    marginRight: theme.spacing(-0.25),
  },
  avatarContainer: {
    padding: theme.spacing(0, 0.75, 0, 0.5),
  },
  userAvatar: {
    border: "2px solid white",
  },
  avatarPlaceholder: {
    backgroundColor: theme.palette.groovy.neutral[400],
    border: "2px solid white",
  },
  avatarPlaceholderText: {
    color: theme.palette.groovy.grey[400],
  },
  userContainer: {
    padding: theme.spacing(0, 0.75),
  },
  nameContainer: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
}))

export default CurriculumProfileListModalListItem
