import ContentModuleList from "@/content-usage/modules/list/ContentModuleList"
import { CurriculumModuleListFragment$key } from "@/product/course/curriculum/section/list/__generated__/CurriculumModuleListFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

interface CurriculumModuleListProps extends TestIDProps {
  curriculumKey: CurriculumModuleListFragment$key
  canReorder?: boolean
  showProgressIcon?: boolean
  hideEmptySections?: boolean
  filterContentLabelIds?: GlobalID[]
  alwaysStartCollapsed?: boolean
}

function CurriculumModuleList({
  curriculumKey,
  canReorder,
  showProgressIcon,
  hideEmptySections,
  filterContentLabelIds,
  alwaysStartCollapsed,
}: CurriculumModuleListProps) {
  const location = useLocation()

  const curriculum = useFragment<CurriculumModuleListFragment$key>(
    graphql`
      fragment CurriculumModuleListFragment on Curriculum
      @argumentDefinitions(contentLabelIds: { type: "[ID!]" }) {
        id
        modules {
          ...ContentModuleListFragment @arguments(contentLabelIds: $contentLabelIds)
        }
      }
    `,
    curriculumKey
  )

  useEffect(() => {
    const element = document.getElementById(location.hash)
    if (element) element.scrollIntoView({ behavior: "smooth" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash])

  if (!curriculum?.modules) return null

  return (
    <ContentModuleList
      testid={"CurriculumModuleList"}
      entityId={curriculum.id}
      contentUsageConnectionKey={curriculum.modules}
      canReorder={canReorder}
      showProgressIcon={showProgressIcon}
      hideEmptySections={hideEmptySections}
      filterContentLabelIds={filterContentLabelIds}
      alwaysStartCollapsed={alwaysStartCollapsed}
    />
  )
}

export default CurriculumModuleList
