/**
 * @generated SignedSource<<a59d272dd489d74ee69184b9df4a0923>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateContentCompletionCsvInput = {
  contentUsageId: string;
};
export type CurriculumProfileListModalContentCSVMutation$variables = {
  input: CreateContentCompletionCsvInput;
};
export type CurriculumProfileListModalContentCSVMutation$data = {
  readonly response: {
    readonly downloadCSVLink: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type CurriculumProfileListModalContentCSVMutation = {
  variables: CurriculumProfileListModalContentCSVMutation$variables;
  response: CurriculumProfileListModalContentCSVMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateContentCompletionCsvResponse",
    "kind": "LinkedField",
    "name": "createContentCompletionCsv",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadCSVLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurriculumProfileListModalContentCSVMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurriculumProfileListModalContentCSVMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "84491453458d4d914730745226ed1a83",
    "id": null,
    "metadata": {},
    "name": "CurriculumProfileListModalContentCSVMutation",
    "operationKind": "mutation",
    "text": "mutation CurriculumProfileListModalContentCSVMutation(\n  $input: CreateContentCompletionCsvInput!\n) {\n  response: createContentCompletionCsv(input: $input) {\n    downloadCSVLink\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f8de3a14feeff185f535bf4e57349fe";

export default node;
