import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"

export type CurriculumProfileListModalTab = "completed" | "incompleted"

interface CurriculumProfileListModalTabListProps {
  activeTab: CurriculumProfileListModalTab
  setActiveTab: React.Dispatch<React.SetStateAction<CurriculumProfileListModalTab>>
}

function CurriculumProfileListModalTabList({
  activeTab,
  setActiveTab,
}: CurriculumProfileListModalTabListProps) {
  const classes = useStyles()

  return (
    <DiscoTabs
      testid={"CurriculumProfileListModal.TabList"}
      tabVariant={"default"}
      className={classes.tabs}
      routes={[
        {
          label: "Completed",
          onClick: () => {
            setActiveTab("completed")
          },
          testid: "completed",
          active: activeTab === "completed",
        },
        {
          label: "Incompleted",
          onClick: () => {
            setActiveTab("incompleted")
          },
          testid: "incomplete",
          active: activeTab === "incompleted",
        },
      ]}
    />
  )
}

const useStyles = makeUseStyles({
  tabs: {
    marginBottom: "-2px",
  },
})

export default CurriculumProfileListModalTabList
